<template>
	<div>
		<!-- 公共头部 -->
		<Header :title="courseChapterInfo.subject_title"></Header>
		
		<div class="view1">
			<div class="video">
				<video-player class="video-player vjs-custom-skin"
					v-if="showVideo"
					ref="videoPlayer"
					:playsinline="true"
					:options="playerOptions"
					@play="onPlayerPlay"
					@pause="onPlayerPause"
					@ended="onPlayerEnded"
					@timeupdate="onPlayerTimeupdate"
					@loadeddata="onPlayerLoadeddata"
					@ready="playerReadied">
				</video-player>
			</div>
			<div class="return_info_box">
				<div class="return_info">
					<div class="title">{{courseDetail.info.title}} （{{courseDetail.info.keshi}}学时）</div>
					<div class="info clearfix">
						<span class="fl time">{{courseDetail.info.addtime}}</span>
						<span class="fl num">{{courseDetail.info.num}}</span>
					</div>
				</div>
				<!-- <div class="button_group">
					<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + courseDetail.prev_id + '&student_id=' + $route.query.student_id" class="btn" v-show="courseDetail.prev_id">上一节</router-link>
					<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + courseDetail.next_id + '&student_id=' + $route.query.student_id" class="btn btn1" v-show="courseDetail.next_id">下一节</router-link>
				</div> -->
			</div>
		</div>
		
		<div class="view2">
			<div class="cate-title">章节目录</div>
			<ul class="yiji">
				<li v-for="(item,index) in courseChapterInfo.chapter">
					<div class="yiji-box" :class="[ item.openStatus ? 'active' : '' ]" @click="item.openStatus = !item.openStatus">
						<img class="icon" src="@/assets/images/icon73.png" />
						<div class="title">{{item.title}} （{{item.keshi}}学时）
						
						</div>
						
					</div>
					<ul class="erji" :class="[ item.openStatus ? 'active' : '' ]">
						<template v-for="(items,idx) in item.lists">
							<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + items.id + '&student_id=' + $route.query.student_id" v-if="index == 0 && idx == 0" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</router-link>
							<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + items.id + '&student_id=' + $route.query.student_id" v-else-if="index == 0 && idx != 0 && item.lists[idx - 1].status == '已学完'" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</router-link>
							<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + items.id + '&student_id=' + $route.query.student_id" v-else-if="index != 0 && idx == 0 && courseChapterInfo.chapter[index - 1].lists[courseChapterInfo.chapter[index - 1].lists.length - 1].status == '已学完'" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</router-link>
							<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + items.id + '&student_id=' + $route.query.student_id" v-else-if="index != 0 && idx != 0 && item.lists[idx - 1].status == '已学完'" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</router-link>
							<li v-else @click="$toast.error('请先学习完上一节再观看');" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</li>
						</template>
					</ul>
				</li>
			</ul>
		</div>
			
		<div class="mask" v-if="showMask"></div>
		<div class="alert-box" :class="[ showMask ? 'active' : '' ]">
			<div class="alert-view1">
				<span class="type">{{problem[curIndex].type}}</span>
				<div class="question_title">{{problem[curIndex].title}}</div>
			</div>
			<div class="alert-view2">
				<li class="clearfix" v-for="(item,index) in problem[curIndex].answer">
					<label class="radio fl" v-if="problem[curIndex].type == '单选题' || problem[curIndex].type == '判断题'">
						<input class="input_radio" type="radio" :value="item.option" :disabled="problem[curIndex].disabled" v-model="problem[curIndex].checkedValue">
						<span></span>
						<a>{{item.option}}、{{item.title}}</a>
					</label>
					<label class="checkbox fl" v-if="problem[curIndex].type == '多选题'">
						<input class="input_checkbox" type="checkbox" :value="item.option" :disabled="problem[curIndex].disabled" v-model="problem[curIndex].checkVal">
						<span></span>
						<a>{{item.option}}、{{item.title}}</a>
					</label>
				</li>
			</div>
			<div class="alert-view3">
				<div class="status1" v-if="status == 0">提示：答完题后才可以继续观看视频！</div>
				<div class="status3" v-if="status == 1">回答正确!</div>
				<div class="status2" v-if="status == 2">回答错误！正确答案：{{problem[curIndex].daan}}</div>
			</div>
			<div class="button_group">
				<!-- 继续观看 -->
				<div class="btn" @click="submit" v-if="status == 0">提交答案</div>
				<div class="btn" @click="closeMask" v-if="status != 0">继续观看</div>
			</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	
	import { videoPlayer } from 'vue-video-player';
	import 'video.js/dist/video-js.css';
	export default {
		components: {
			Header,
			Footer,
			videoPlayer
		},
		data() {
			return {
				courseChapterInfo: '',
				courseDetail: {
					info: '',
					next_id: '',
					prev_id: '',
					problem_count: 0,
					yks: 0
				},
				showMask: false,
				showVideo: false,
				playerOptions: {
					playbackRates: [1.0], // 可选的播放速度
					autoplay: false, // 如果为true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 是否视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:10', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{}],
					poster: '', // 封面地址
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true, // 当前时间和持续时间的分隔符
						durationDisplay: true, // 显示持续时间
						remainingTimeDisplay: true, // 是否显示剩余时间功能
						fullscreenToggle: true // 是否显示全屏按钮
					}
				},
				duration: 0, // 视频总时长
				currentTime: 0, // 视频播放进度
				timer: '',//定时器名字
				timer1: '',//定时器名字
				problem: [{}],
				array: [],
				curIndex: 0,
				status: 0, // 0初始化 1回答正确 2回答错误
			}
		},
		mounted() {
			
		
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		watch: {
			$route(to,form) {
				if((to.path == form.path) && this.$route.path == '/learnCenter/video'){
					this.getCourseDetail();
				}
			},
		},
		activated() {
			this.getCourseDetail();
			this.getList();
		},
		deactivated() {
			clearInterval(this.timer);
			clearInterval(this.timer1);
			this.problem = [{}];
			this.playerOptions.poster = '';
			this.playerOptions.sources[0]['src'] = '';
			this.showVideo = false;
			this.courseDetail = {
				info: '',
				prev_id: '',
				next_id: '',
				problem_count: 0,
				yks: 0
			};
		},
		methods: {
			closeMask() {
				this.showMask = false;
				this.status = 0;
				this.$refs.videoPlayer.player.play();
			},
			// 提交答案
			submit() {
				var curIndex = this.curIndex;
				var problem = this.problem;
				if(problem[curIndex].type == '单选题' || problem[curIndex].type == '判断题'){
					// 判断题目对错
					var a = problem[curIndex].checkedValue;
					if(!a){
						this.$messageBox.alert('请先选择答案再提交！', '温馨提示');
						return false;
					}
					problem[curIndex]['disabled'] = true; // 使题目不可选择
					var b = problem[curIndex].daan;
					if(a == b){
						this.status = 1;
					} else {
						this.status = 2;
					}
				}
				if(problem[curIndex].type == '多选题'){
					var a = problem[curIndex].checkVal;
					if(!a.length){
						this.$messageBox.alert('请先选择答案再提交！', '温馨提示');
						return false;
					}
					problem[curIndex]['disabled'] = true; // 使题目不可选择
					var answer = problem[curIndex].daan;
					var b = answer.split('');
					if(JSON.stringify(a.sort()) == JSON.stringify(b.sort())){
						this.status = 1;
					} else {
						this.status = 2;
					}
				}
			},
			// 监听播放的时间点，弹出问题
			updateCurrentTime() {
				var currentTime = parseInt(this.currentTime);
				var array = this.array;
				for(var i = 0; i < array.length; i++) {
					if(array[i] == currentTime) {
						if(this.$refs.videoPlayer.player.isFullscreen()){
							this.$refs.videoPlayer.player.exitFullscreen();
						}
						this.curIndex = i;
						this.$refs.videoPlayer.player.pause();
						clearInterval(this.timer);
						clearInterval(this.timer1);
						this.showMask = true;
					}
				}
			},
			// 更新播放进度
			async jinduUpdate(is_end = 0) {
				var course_id = this.$route.query.course_id;
				var student_id = this.$route.query.student_id;
				var times = this.currentTime;
				var uid = this.$store.state.userInfo.id;
				var ztime = this.duration;
				var data = {
					course_id, student_id, times, uid, ztime
				}
				data['is_end'] = is_end;
				const { data:res } = await this.$http.get('/study/jinduUpdate', { params: data });
				if(this.$store.state.beta){
					console.log('更新播放进度：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
			},
			// 开始播放
			onPlayerPlay(player) {
				var that = this;
				that.timer = setInterval(function () {
					that.jinduUpdate();
				}, 3000);
				that.timer1 = setInterval(function () {
					that.updateCurrentTime();
				}, 1000);
			},
			// 暂停播放
			onPlayerPause(player) {
				clearInterval(this.timer);
				clearInterval(this.timer1);
			},
			// 播放结束
			onPlayerEnded(player) {
				this.$messageBox.confirm(
				'该章节已学习完毕，请学习下一章节'
				).then(() => {
				var that = this;
							clearInterval(that.timer);
							clearInterval(that.timer1);
							that.jinduUpdate(1);
							that.getList();
							
				}).catch(err=>{})
				
			},
			// 当前播放位置发生变化时触发。
			onPlayerTimeupdate(player) {
				var currentTime = player.currentTime(); // 获取视频当前播放时间点
				this.currentTime = currentTime;
			},
			onPlayerLoadeddata(player){
				var duration = Number(player.duration());
				if(this.$store.state.beta){
					console.log('设置播放进度：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , duration);
				}
				this.duration = duration; // 获取视频总时长
				var problem_count = Number(this.courseDetail.problem_count);
				if(problem_count){
					var step = parseInt(duration / (problem_count + 1));
					var array = [];
					for(var i = 0; i < problem_count; i++) {
						array[i] = Number(step * (i + 1));
					}
					this.array = array;
				} else {
					this.array = [];
				}
			},
			playerReadied(player) {
				player.currentTime(this.courseDetail.info.startsecond); // 设置视频从上次时间点播放
				document.getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = 'none'; // 禁止拖动播放进度
				if(this.courseDetail.info.startsecond){
					this.$toast('已定位到上次播放时间点');
				}
			},
			// 获取课程详情
			async getCourseDetail() {
				clearInterval(this.timer);
				clearInterval(this.timer1);
				this.problem = [{}];
				this.playerOptions.poster = '';
				this.playerOptions.sources[0]['src'] = '';
				this.showVideo = false;
				this.courseDetail = {
					info: '',
					prev_id: '',
					next_id: '',
					problem_count: 0,
					yks: 0
				};
				var classes_id = this.$route.query.classes_id;
				var course_id = this.$route.query.course_id;
				var uid = this.uid;
				var student_id = this.$route.query.student_id;
				var data = {
					classes_id, course_id, uid, student_id
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/courseDetail', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学习中心视频：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var courseDetail = res.data;
					courseDetail['info']['addtime'] = this.utils.js_date_time(courseDetail['info']['addtime'],1);
					if(courseDetail['info']['img'] && courseDetail['info']['img'].substring(0,4) != 'http'){
						courseDetail['info']['img'] = this.$store.state.http + courseDetail['info']['img'];
					}
					if(courseDetail['info']['video'] && courseDetail['info']['video'].substring(0,4) != 'http'){
						courseDetail['info']['video'] = this.$store.state.http + courseDetail['info']['video'];
					}
					
					var problem = courseDetail.problem;
					if(problem.length){
						for(var i = 0; i < problem.length; i++) {
							problem[i]['disabled'] = false;
							if(problem[i]['type'] == '单选题' || problem[i]['type'] == '判断题'){
								problem[i]['checkedValue'] = '';
							}
							if(problem[i]['type'] == '多选题'){
								problem[i]['checkVal'] = [];
							}
						}
						this.problem = problem;
					} else {
						this.problem = [{}];
					}
					
					this.playerOptions.poster = courseDetail['info']['img'];
					this.playerOptions.sources[0]['src'] = courseDetail['info']['video'];
					this.showVideo = true;
					this.courseDetail = courseDetail;
				} else {
					this.problem = [{}];
					this.playerOptions.poster = '';
					this.playerOptions.sources[0]['src'] = '';
					this.showVideo = false;
					this.courseDetail = {
						info: '',
						prev_id: '',
						next_id: '',
						problem_count: 0,
						yks: 0
					};
					this.$toast.info(res.data);
				}
			},
			// 获取项目列表
			async getList() {
				var uid = this.uid;
				var student_id = this.$route.query.student_id;
				var data = {
					uid, student_id
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/courseChapter', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学习中心项目列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var courseChapterInfo = res.data;
					for(var i = 0; i < courseChapterInfo.chapter.length; i++) {
						courseChapterInfo.chapter[i]['openStatus'] = false;
						for(var j = 0; j < courseChapterInfo.chapter[i].lists.length; j++){
							if(this.$route.query.course_id == courseChapterInfo.chapter[i].lists[j]['id']){
								courseChapterInfo.chapter[i]['openStatus'] = true;
							}
						}
					}
					this.courseChapterInfo = courseChapterInfo;
				} else {
					this.courseChapterInfo = '';
					this.$toast.error(res.data);
				}
			}
		},
	};
</script>

<style scoped>
	.view1{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.video{
		width: 100%;
		height: 4.6rem;
	}
	.return_info_box{
		padding: 0.3rem;
	}
	.return_info_box .return_info{
		
	}
	.return_info_box .return_info .title{
		font-size: 0.32rem;
		color: #333333;
	}
	.return_info_box .return_info .info{
		margin-top: 0.3rem;
	}
	.return_info_box .return_info .info .time{
		font-size: 0.26rem;
		color: #969897;
		padding-left: 0.46rem;
		background: url(../../../assets/images/icon85.png) no-repeat left center;
		background-size: 0.34rem 0.34rem;
		line-height: 0.4rem;
		padding-right: 0.3rem;
		margin-right: 0.3rem;
		position: relative;
	}
	.return_info_box .return_info .info .time::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.02rem;
		height: 0.2rem;
		background: #969897;
		right: 0;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.return_info_box .return_info .info .num{
		font-size: 0.26rem;
		color: #969897;
		padding-left: 0.46rem;
		background: url(../../../assets/images/icon86.png) no-repeat left center;
		background-size: 0.34rem 0.23rem;
		line-height: 0.4rem;
	}
	.return_info_box .button_group{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0.3rem;
	}
	.return_info_box .button_group .btn{
		width: 1.4rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		border: 0.02rem solid #d6d6d6;
		border-radius: 0.5rem;
		font-size: 0.26rem;
		color: #333333;
		outline: none;
		cursor: pointer;
	}
	.return_info_box .button_group .btn1{
		background: #121e5f;
		border: 0.02rem solid #121e5f;
		color: #ffffff;
		margin-left: 0.3rem;
	}
	
	.view2{
		margin-top: 0.3rem;
	}
	.view2 .cate-title{
		height: 0.92rem;
		display: flex;
		align-items: center;
		padding: 0 0.46rem;
		font-size: 0.32rem;
		color: #333333;
		position: relative;
	}
	.view2 .cate-title::before{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.05rem;
		height: 0.26rem;
		background: #ff6000;
		left: 0.3rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.view2 .yiji{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.view2 .yiji>li{
		
	}
	.view2 .yiji>li .yiji-box{
		background: #f6f6f6;
		padding: 0.3rem 0 0.3rem 0.6rem;
		position: relative;
		cursor: pointer;
	}
	.view2 .yiji>li .yiji-box .icon{
		position: absolute;
		width: 0.16rem;
		height: 0.09rem;
		left: 0.24rem;
		top: 50%;
		transform: translate(0,-50%) rotate(-90deg);
		-ms-transform: translate(0,-50%) rotate(-90deg);
		-o-transform: translate(0,-50%) rotate(-90deg);
		-moz-transform: translate(0,-50%) rotate(-90deg);
		-webkit-transform: translate(0,-50%) rotate(-90deg);
		transition: all .3s;
	}
	.view2 .yiji>li .yiji-box.active .icon{
		transform: translate(0,-50%) rotate(0);
		-ms-transform: translate(0,-50%) rotate(0);
		-o-transform: translate(0,-50%) rotate(0);
		-moz-transform: translate(0,-50%) rotate(0);
		-webkit-transform: translate(0,-50%) rotate(0);
	}
	.view2 .yiji>li .yiji-box .title{
		font-size: 0.28rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.view2 .yiji>li .erji{
		background: #ffffff;
		height: 0;
		overflow: hidden;
		transition: all 1s;
	}
	.view2 .yiji>li .erji.active{
		height: auto;
	}
	.view2 .yiji>li .erji .item{
		display: block;
		padding: 0.18rem 0.3rem 0.18rem 0.6rem;
		border-bottom: 0.02rem solid #e7e7e7;
	}
	.view2 .yiji>li .erji .item .title{
		font-size: 0.26rem;
		color: #333333;
		height: 0.44rem;
		line-height: 0.44rem;
		max-width: 4.8rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.view2 .yiji>li .erji .item .jindu{
		width: 0.8rem;
		text-align: right;
		font-size: 0.24rem;
		color: #333333;
		height: 0.44rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.view2 .yiji>li .erji .item .btn{
		font-size: 0.24rem;
		color: #1fbb00;
		background: #ffffff;
		border: 0.02rem solid #1fbb00;
		border-radius: 0.04rem;
		padding: 0.04rem 0.08rem;
	}
	.view2 .yiji>li .erji .item .btn1{
		color: #ff6000;
		border: 0.02rem solid #ff6000;
	}
	.view2 .yiji>li .erji .item .btn2{
		color: #c7c7c7;
		border: 0.02rem solid #c7c7c7;
	}
	
	
	.alert-box{
		position: fixed;
		width: 6.9rem;
		z-index: 999;
		background: #ffffff;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) scale(0);
		-ms-transform: translate(-50%,-50%) scale(0);
		-o-transform: translate(-50%,-50%) scale(0);
		-moz-transform: translate(-50%,-50%) scale(0);
		-webkit-transform: translate(-50%,-50%) scale(0);
		border-radius: 0.14rem;
		padding: 0.4rem;
		transition: all .3s;
	}
	.alert-box.active{
		transform: translate(-50%,-50%) scale(1);
		-ms-transform: translate(-50%,-50%) scale(1);
		-o-transform: translate(-50%,-50%) scale(1);
		-moz-transform: translate(-50%,-50%) scale(1);
		-webkit-transform: translate(-50%,-50%) scale(1);
	}
	
	.alert-view1{
		position: relative;
		padding-left: 1rem;
	}
	.alert-view1 .type{
		width: 0.9rem;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #121e5f;
		font-size: 0.24rem;
		color: #ffffff;
		position: absolute;
		border-radius: 0.06rem;
		left: 0;
		top: 0;
	}
	.alert-view1 .question_title{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.alert-view1 .img{
		display: block;
		margin: 0.2rem auto 0;
	}
	.alert-view2{
		margin-top: 0.2rem;
	}
	.alert-view3{
		height: 1.95rem;
		position: relative;
	}
	.alert-view3 .status1{
		font-size: 0.26rem;
		color: #ff2828;
		padding-left: 0.48rem;
		background: url(../../../assets/images/icon87.png) no-repeat left center;
		background-size: 0.3rem 0.3rem;
		line-height: 0.3rem;
		position: absolute;
		width: fit-content;
		bottom: 0.36rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
	}
	.alert-view3 .status2{
		font-size: 0.26rem;
		color: #ff2828;
		padding-top: 0.95rem;
		background: url(../../../assets/images/icon88.png) no-repeat top center;
		background-size: 0.65rem 0.65rem;
		position: absolute;
		width: fit-content;
		bottom: 0.4rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
	}
	.alert-view3 .status3{
		font-size: 0.26rem;
		color: #009d30;
		padding-top: 0.95rem;
		background: url(../../../assets/images/icon89.png) no-repeat top center;
		background-size: 0.65rem 0.65rem;
		position: absolute;
		width: fit-content;
		bottom: 0.4rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
	}
	.alert-box .button_group{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.alert-box .button_group .btn{
		width: 2rem;
		height: 0.7rem;
		border-radius: 0.5rem;
		font-size: 0.26rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		background: #121e5f;
	}
</style>